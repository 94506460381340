.relative {
  position: relative;
}

.resetList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mainwidth {
  width: 800px;
  margin: 0 auto;
  padding: 0;
}

.border-radius-bottom {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.absolute {
  z-index: 2;
  position: absolute;
}

.mask {
  z-index: 20;
  position: relative;
}

.ontop {
  z-index: 10;
  height: 450px;
  background: #fff;
  position: relative;
}

#kreis-1, #kreis-2, #kreis-3, #kreis-4, #kreis-5, #kreis-6 {
  display: none;
}

img {
  width: 100%;
}

.visible-tablet, .disable-mobile {
  display: block !important;
}

.visible-mobile, .visible-desktop {
  display: none !important;
}

.clear {
  clear: both;
}

div.cols div {
  float: left;
}

div.cols div.last {
  float: right;
  margin: 0 0 0 -10px;
}

.row-2 {
  width: 100px;
}

.row-3 {
  width: 150px;
}

.row-4 {
  width: 200px;
}

.row-5 {
  width: 250px;
}

.row-6 {
  width: 300px;
}

.row-7 {
  width: 350px;
}

.row-8 {
  width: 400px;
}

.row-9 {
  width: 450px;
}

.row-10 {
  width: 500px;
}

.row-11 {
  width: 550px;
}

.row-12 {
  width: 600px;
}

.row-13 {
  width: 650px;
}

.row-14 {
  width: 700px;
}

div.mainwidth {
  width: 800px;
  margin: 0 auto;
  padding: 0;
}

header {
  height: 116px;
  width: 100%;
  z-index: 2000;
  background: url("headerback.65c3651b.png") 0 0 repeat-x;
  position: fixed;
  top: 0;
}

section {
  min-height: 200px;
  z-index: 20;
  position: relative;
}

section#home {
  color: #54758c;
  z-index: 10;
  background: #fff;
  margin: 116px 0 5px;
  padding: 0;
  box-shadow: 0 5px 1px #999;
}

section#home div.fair {
  display: none;
}

section#space {
  height: 387px;
  z-index: 1;
  background: #fafbf1;
  margin: 0;
  padding: 0;
}

section#angebot, section#netzwerk {
  color: #fff;
  background: #54758c;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#angebot {
  background: #54758c url("border.c2bfd3d0.png") 0 0 repeat-x;
  margin-top: 0;
}

section#profil, section#referenzen, section#kontakt {
  color: #54758c;
  background: #fff;
  margin: 116px 0;
  padding: 56px 0 70px;
}

section#referenzen {
  padding: 0 0 70px;
}

section#impressum {
  color: #fff;
  background: #575759;
  margin: 116px 0 0;
  padding: 56px 0 70px;
}

section#home a, section#profil a, section#referenzen a, section#kontakt a {
  color: #54758c;
}

section#impressum a, section#angebot a, section#netzwerk a {
  color: #fff;
}

nav ul {
  -moz-border-radius-bottomright: 12px;
  -moz-border-radius-bottomleft: 12px;
  width: 535px;
  height: 40px;
  float: left;
  background: #575759;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0;
  padding: 20px 0 0;
  list-style: none;
}

nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline;
}

nav ul li a:link, nav ul li a:visited {
  color: #fff;
  text-transform: uppercase;
  padding: 0 0 0 20px;
  font-size: 13px;
  text-decoration: none;
}

nav ul li a.current, nav ul li a:hover, nav ul li a:active {
  background: url("navact.a9ac7e71.png") 5px 5px no-repeat;
}

div#logo {
  float: right;
}

div#logo a.logo {
  height: 79px;
  width: 250px;
  white-space: nowrap;
  text-indent: 999em;
  background: url("logo2.df22bdbc.png") 0 100% no-repeat;
  display: block;
  overflow: hidden;
}

/*# sourceMappingURL=index.650ec51b.css.map */
